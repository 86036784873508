@tailwind base;
@tailwind components;
@tailwind utilities;

.neo_pcolor {
  color: #6ad5ff;
}

.neo_scolor {
  color: #006eac;
}

@font-face {
  font-family: 'AvenirRoman';
  src: local('Avenir'), url('./fonts/avenir_ff/AvenirLTStd-Roman.otf') format('truetype');
}
@font-face {
  font-family: 'AvenirMedium';
  src: local('Avenir'), url('./fonts/avenir_ff/Avenir\ Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'AvenirBlack';
  src: local('Avenir'), url('./fonts/avenir_ff/AvenirLTStd-Black.otf') format('truetype');
}

@font-face {
  font-family: 'AvenirHeavy';
  src: local('Avenir'), url('./fonts/avenir_ff/Avenir\ Heavy.ttf') format('truetype');
}
